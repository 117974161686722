<div *ngIf="orderState.isLoading()" class="blackout blackout-white">
  <div class="spinner"></div>
</div>

<app-shipping-details
  *ngIf="isReview"
  [planType]="planType"
  [isReview]="isReview"
  class="md-show"
></app-shipping-details>

<div *ngIf="isReview" class="separator md-show"></div>

<div class="order-summary-body">
  <h3 *ngIf="isReview" class="section-header summary-plan-title">Order Summary</h3>

  <app-order-plan-details
    *ngIf="!isHybridVendorPlan && planType !== 'hybrid'; else hybridPlanDetails"
    [isVendorSpecific]="isVendorSpecific"
  >
  </app-order-plan-details>

  <ng-template #hybridPlanDetails>
    <app-order-plan-details [isVendorSpecific]="isVendorSpecific" [sectionPlanType]="'trakViewPro'">
    </app-order-plan-details>
    <div class="separator"></div>
    <app-order-plan-details [isVendorSpecific]="isVendorSpecific" [sectionPlanType]="'gpsPremium'">
    </app-order-plan-details>
  </ng-template>

  <div class="separator"></div>

  <div class="summary-line-item">
    <span>Subtotal:</span>
    <span>{{ orderState.subTotalPriceWithDiscount() | price }}</span>
  </div>

  <ng-container *ngIf="promoCode?.type !== 'invalid' && promoCode?.couponCode">
    <div class="summary-line-item">
      <span>Promo Code:</span>
      <span
        ><b>{{ promoCode?.couponCode }}</b> applied</span
      >
    </div>
    <ng-container [ngSwitch]="promoCode?.type">
      <span *ngSwitchCase="'percentage'">
        <span *ngIf="!isVendorSpecific; else byDeviceTplVWO">
          {{ $any(promoCode)?.discountPercentage }}% off subscription cost
        </span>
        <ng-template #byDeviceTplVWO>
          <span>{{ orderState.getVendorSpecificDiscountLabel() }}</span>
        </ng-template>
      </span>
      <span *ngSwitchCase="'fixed'" class="discount-details">
        {{ $any(promoCode)?.fixedAmount | price }} off subscription cost
      </span>
      <span *ngSwitchCase="'free'" class="discount-details">
        Free for the first
        {{ $any(promoCode)?.trialLength }}
        {{ $any(promoCode)?.trialUnit === 'day' ? 'days' : 'months' }}
      </span>
    </ng-container>
    <div class="summary-line-item">
      <span>Discount:</span>
      <span>-{{ orderState.discountAmount() | price }}</span>
    </div>
  </ng-container>

  <div class="summary-line-item">
    <span>Shipping:</span>
    <span>FREE</span>
  </div>

  <div class="summary-line-item">
    <span>Sales Tax:</span>
    <span>{{ orderState.taxesPrice() | price }}</span>
  </div>

  <div class="separator"></div>

  <div class="summary-line-item">
    <span>Total:</span>
    <span>{{ orderState.subTotalPriceWithTaxes() | price }}</span>
  </div>
</div>

<app-shipping-details
  *ngIf="!isReview"
  [planType]="planType"
  [isReview]="isReview"
  class="md-show"
></app-shipping-details>

<div class="payment-details-section">
  <app-payment-details
    [isReview]="isReview"
    [reCaptchaFailed]="reCaptchaFailed"
    [counter]="counter"
    (toggleReview)="toggleReview.emit()"
  ></app-payment-details>

  <ng-content></ng-content>
</div>

<app-modal modalId="hdc-info-modal" [isCloseable]="true">
  <app-hdc-info-modal></app-hdc-info-modal>
</app-modal>
<app-modal modalId="confirm-close">
  <app-confirm-close></app-confirm-close>
</app-modal>
