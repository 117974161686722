import { TPlanType } from '../core/models/i-order-state.model';

const DEFAULT_PARSE_REGEXP = /\{([^\}]+)?\}/g;
/**
 * this is a primitive template engine that can iterpolate var inside braces {varName}
 */
export function interpolateStr(tpl: string, data: { [propName: string]: any }, parseRegexp?: RegExp): string {
  const re = parseRegexp || DEFAULT_PARSE_REGEXP;
  let resultTpl = tpl;
  let match;
  // tslint:disable-next-line: no-conditional-assignment
  while ((match = re.exec(resultTpl))) {
    resultTpl = resultTpl.replace(match[0], data[match[1]] || '');
  }
  return resultTpl;
}

export async function encryptSha256(message: string): Promise<string> {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export const uppercaseFirstChars = (str: string): string => {
  const words = str.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substring(1);
  }
  const itemName = words.join(' ');
  return itemName;
};

export const planTypeName = (planType: TPlanType): string => {
  switch (planType) {
    case 'gpsStarter':
      return 'GPS Starter';
    case 'gpsPremium':
      return 'GPS Premium';
    case 'trakViewPro':
      return 'TrakView Pro';
    case 'hybrid':
      return 'GPS Premium & TrakView Pro';
    default:
      return 'GPS Premium';
  }
};

export const planDeviceName = (planType: TPlanType): string => {
  switch (planType) {
    case 'trakViewPro':
      return 'Dashcams';
    default:
      return 'Trackers';
  }
};

export const planDevicePreviewImg = (planType: TPlanType): string => {
  switch (planType) {
    case 'trakViewPro':
      return 'assets/images/trakview-pro-preview.webp';
    case 'gpsStarter':
      return 'assets/images/gps-starter-preview.webp';
    default:
      return 'assets/images/gps-premium-preview.webp';
  }
};

export const planDeviceImg = (planType: TPlanType): string => {
  if (planType === 'trakViewPro') {
    return 'assets/images/dashcam.webp';
  } else {
    return 'assets/images/tracker.webp';
  }
};
